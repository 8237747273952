import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  doesSessionExist,
  useSessionContext,
} from "supertokens-auth-react/recipe/session";

import MusicPlayerFooter from "../components/MusicPlayerFooter/MusicPlayerFooter";
import SearchBar from "../components/SearchBar";
import { Button } from "../primitives";

import logo from "../assets/images/stagedive-logo.png";
import "./Layout.css";

import {
  faFacebook,
  faInstagram,
  faTiktok,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import NotificationsDropdown from "components/NotificationsDropDown";
import { usePlayerContext } from "context/PlayerContext";
import { useGetMe, useGetUserDeletionRequest } from "hooks";
import StagediveRTC from "rtc";

const Layout = () => {
  const [sessionExists, setSessionExists] = useState(false);
  const [showMusicPlayer, setShowMusicPlayer] = useState(true);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const session = useSessionContext();
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();

  const { data: user } = useGetMe({ enabled: sessionExists });

  const { data: userDeletionRequest } = useGetUserDeletionRequest(user?.id);

  const { setCanStream, canStream } = usePlayerContext();

  useEffect(() => {
    async function fetchUserData() {
      try {
        if (await doesSessionExist()) {
          if (userDeletionRequest) {
            navigate("/deletion-request");
          }

          const hideOnPaths = ["/get-stagedive", "/signup/checkout"];
          const showMusicPlayer = !hideOnPaths.includes(
            window.location.pathname
          );
          setSessionExists(true);
          setShowMusicPlayer(showMusicPlayer);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchUserData();
  }, [session, userDeletionRequest]);

  useEffect(() => {
    const hideOnPaths = ["/get-stagedive", "/signup/checkout"];
    const showMusicPlayer =
      sessionExists && !hideOnPaths.includes(window.location.pathname);
    setShowMusicPlayer(showMusicPlayer);
  }, [location]);

  //Checks to see if the hls is initialized then loads current song.
  useEffect(() => {
    const isSubscriptionActive = (subscription: Subscription) => {
      if (subscription === null) return false;
      else {
        const today = new Date();

        // Set your start and end dates (replace these with your actual dates)
        const startDate = new Date(subscription.startDate);
        const endDate = new Date(subscription.endDate);

        // Check if today is between the start and end dates
        return today >= startDate && today <= endDate;
      }
    };

    if (user) {
      setCanStream(isSubscriptionActive(user.subscription));
    }
  }, [setCanStream, user]);

  useEffect(() => {
    if (user) {
      const rtc = new StagediveRTC(user, queryClient);
      rtc.connect();
    }
  }, [user]);

  function toggleFeedbackForm() {
    setShowFeedbackForm(!showFeedbackForm);
    // $("#feedback-form").toggleClass("show");
    // let updateIcon = $("#feedback-form").hasClass("show")
    //   ? "chevron_left"
    //   : "bug_report";
    // $("#feedback-form button span").text(updateIcon);
  }

  return (
    <>
      <nav
        aria-label="Main Menu"
        id="main-menu"
        className="backdrop-blur-sm	bg-background/95 flex items-center"
      >
        <Link
          to="/home"
          className="logo"
          aria-hidden="false"
          aria-label="StageDive - Home"
          title="StageDive - Home"
        >
          <img src={logo} alt="StageDive Logo" />
        </Link>
        {!canStream && (
          <Link
            className="subscribe text-primary hidden md:block"
            to="/profile/subscriptions"
          >
            <Button variant={"outline"}>Subscribe to Stream</Button>
          </Link>
        )}

        {sessionExists && (
          <div className="w-1/4">
            <SearchBar />
          </div>
        )}

        <ul className="nav-links">
          {sessionExists && (
            <li>
              <NotificationsDropdown />
            </li>
          )}
          {sessionExists && (
            <li>
              <Link to="/library" aria-label="My Library" title="My Library">
                <span className="material-symbols-rounded library-link text-primary">
                  library_music
                </span>
              </Link>
            </li>
          )}
          {sessionExists && (
            <li>
              <Link
                to="/browse"
                aria-label="Browse Genres"
                title="Browse Genres"
              >
                <span className="material-symbols-rounded browse">browse</span>
              </Link>
            </li>
          )}
          {sessionExists && (
            <Link to="/profile" aria-label="Profile" title="Profile">
              <span className="material-symbols-rounded profile-link text-primary">
                person
              </span>
            </Link>
          )}
          <li></li>
        </ul>
      </nav>

      <main className="flex flex-col">
        <div
          className=" pointer-events-none absolute select-none  inset-x-0 top-[-10rem]  mix-blend-screen transform-gpu overflow-hidden blur-3xl sm:top-[-20rem] "
          aria-hidden="true"
        >
          <div
            className="relative left-1/2 select-none aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ea4b7e] to-[#329AE6] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div id="feedback-form" className={`${showFeedbackForm ? "show" : ""}`}>
          <iframe
            title="loading"
            src="https://docs.google.com/forms/d/e/1FAIpQLSeyLuRnQPS8BTQIXDN4grbJjhXQB-ekGD9AvaUrZp4YjqyPzg/viewform?embedded=true"
          >
            Loading…
          </iframe>
          <button
            title="Report a Bug/Feedback"
            aria-label="Report a Bug/Feedback"
            onClick={toggleFeedbackForm}
          >
            <span className="material-symbols-rounded">bug_report</span>
          </button>
          <div className="overlay" onClick={toggleFeedbackForm}></div>
        </div>

        <Outlet />

        <footer
          id="resources-footer"
          aria-label="StageDive Resources"
          className="flex mt-auto mx-[10vw] flex-col gap-2 lg:flex-row lg:gap-6 lg:text-center py-6 border-t border-black-500"
        >
          <div id="landing-socials" className="social-links">
            <a
              href="https://www.facebook.com/getstagedive"
              className="fab fa-facebook"
            >
              <FontAwesomeIcon icon={faFacebook} size="xl" />
            </a>
            <a
              href="https://twitter.com/getstagedive"
              className="fab fa-twitter"
            >
              <FontAwesomeIcon icon={faXTwitter} size="xl" />
            </a>
            <a
              href="https://www.instagram.com/getstagedive/"
              className="fab fa-instagram"
            >
              <FontAwesomeIcon icon={faInstagram} size="xl" />
            </a>
            <a
              href="https://www.tiktok.com/@getstagedive"
              className="fab fa-tiktok"
            >
              <FontAwesomeIcon icon={faTiktok} size="xl" />
            </a>
          </div>
          <Link to="/add-to-home" className="my-auto underline">
            Get the App
          </Link>
          <Link to="/about" className="my-auto underline">
            About
          </Link>
          <a
            className="my-auto underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://legal.stagedive.com/tos.html"
          >
            Terms of Service
          </a>
          <Link to="/faq" className="my-auto underline">
            FAQ
          </Link>
          <Link to="/support-questions" className="my-auto underline">
            Support Questions
          </Link>
          <p className="ml-auto">© {new Date().getFullYear()} StageDive LLC</p>
        </footer>
      </main>

      <footer aria-label="Music Controls">
        {showMusicPlayer && <MusicPlayerFooter />}
      </footer>
    </>
  );
};

export default Layout;

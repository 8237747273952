import { PlayIcon } from "@radix-ui/react-icons";
import { usePlaylistPlayer } from "context/playlistPlayer";
import React from "react";
import { SkeletonCardVertical } from "../../components/MusicSections";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../Carousel";
import MusicCard from "../MusicCard/MusicCard";
import "./SmallCardCarousel.css";

interface SmallCardCarouselProps {
  title: string;
  tracks: Track[];
  playlistID?: string;
}

interface Card {
  id: string;
  albumArt: string;
  artist: string;
  songName: string;
}

interface CardData {
  cards: Card[];
}

const SmallCardCarousel: React.FC<SmallCardCarouselProps> = ({
  title,
  tracks,
}) => {
  const { clearAndLoadNewPlaylist } = usePlaylistPlayer();

  return (
    <div className="music-section">
      <h2 className="scroll-m-20 text-primary pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        {title}
        {tracks.length > 0 ? (
          <PlayIcon
            onClick={() => {
              clearAndLoadNewPlaylist(tracks, 0);
            }}
            className="w-8 h-8 inline border rounded-full p-1 ml-6 text-[#ffe561]/75  cursor-pointer hover:text-[#ffe561] hover:border-[#ffe561] hover:bg-secondary/75 fill-white"
          />
        ) : null}
      </h2>
      <Carousel
        opts={{
          align: "start",
        }}
        className="w-full"
      >
        {tracks.length > 0 ? (
          <CarouselContent>
            {tracks.map((track, index) => (
              <CarouselItem
                key={track.id}
                className="basis-1/2 md:basis-1/4 lg:basis-36"
              >
                <MusicCard
                  trackArray={tracks}
                  index={index}
                  key={track.id}
                  track={track}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        ) : (
          <CarouselContent>
            {new Array(12).fill(null).map((fill, index) => (
              <CarouselItem
                key={index}
                className="basis-1/2 md:basis-1/4 lg:basis-36"
              >
                <SkeletonCardVertical />
              </CarouselItem>
            ))}
          </CarouselContent>
        )}

        <div className="w-full flex items-center justify-end gap-4 pt-1">
          <CarouselPrevious />

          <CarouselNext />
        </div>
      </Carousel>
    </div>
  );
};

export { SmallCardCarousel };

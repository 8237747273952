import { PlayIcon } from "@radix-ui/react-icons";
import { usePlaylistPlayer } from "context/playlistPlayer";
import { useGetTopTracks } from "hooks/query";
import { useState } from "react";

export default function TopTracksLeaderboard() {
  const [timeframe, setTimeFrame] = useState("week");

  const { data = [], isPending } = useGetTopTracks({
    timeframe,
    limit: 20,
    offset: 0,
  });

  const getRankStyles = (rank) => {
    switch (rank) {
      case 1:
        return "bg-yellow-500 text-4xl w-12 h-12 text-white font-bold";
      case 2:
        return "bg-gray-400 text-3xl w-10 h-10 text-white font-semibold";
      case 3:
        return "bg-amber-700 text-2xl w-8 h-8 text-white font-semibold";
      default:
        return "bg-gray-200 text-lg w-6 h-6 text-gray-600";
    }
  };

  const { clearAndLoadNewPlaylist } = usePlaylistPlayer();

  return (
    <div id="tracks-leaderboard" className="mt-5 rounded-lg">
      <h2 className="text-3xl font-bold mb-6">
        Popular{" "}
        <select
          className="select select-xs select-ghost"
          onChange={(event) => {
            setTimeFrame(event.target.value);
          }}
        >
          <option value="week">this week</option>
          <option value="month">this month</option>
          <option value="year">this year</option>
        </select>
        {data.length > 0 ? (
          <PlayIcon
            onClick={() => {
              clearAndLoadNewPlaylist(data, 0);
            }}
            className="w-8 h-8 inline border rounded-full p-1 ml-6 text-[#ffe561]/75  cursor-pointer hover:text-[#ffe561] hover:border-[#ffe561] hover:bg-secondary/75 fill-white"
          />
        ) : null}
      </h2>

      {isPending && <span className="loading loading-spinner"></span>}
      <div className="h-64 p-4 grid grid-cols-1 md:grid-cols-4 gap-4 overflow-y-scroll scroll-smooth rounded-sm">
        {data.map((track, index) => (
          <div
            key={track.id}
            className="h-fit group rounded-lg p-2 border border-gray-650 shadow-md hover:shadow-lg transition-shadow duration-200 relative flex items-center hover:cursor-pointer hover:border-gray-500"
            onClick={() => clearAndLoadNewPlaylist(data, index)}
          >
            <div className="absolute -top-3 -left-3 z-10">
              <div
                className={`flex items-center justify-center rounded-full ${getRankStyles(index + 1)}`}
              >
                {index + 1}
              </div>
            </div>
            <div className="relative flex-shrink-0 mr-4">
              <div>
                <img
                  src={
                    track.album.coverImageUrl
                      ? track.album.coverImageUrl
                      : "./stagedive-logo.png"
                  }
                  alt={`${track.name} album art`}
                  className="w-16 h-16 object-cover rounded-md group-hover:opacity-25 transition-opacity duration-200"
                />
                <PlayIcon
                  className={
                    "w-8 h-8 opacity-0 absolute top-4 right-3 group-hover:bg-secondary/85 group-hover:opacity-100 rounded-full p-2"
                  }
                />
              </div>
            </div>
            <div className="flex-grow min-w-0">
              <h3
                className="font-semibold text-white truncate"
                title={track.name}
              >
                {track.name}
              </h3>
              <p
                className="text-white text-sm truncate"
                title={track.creators[0].name}
              >
                {track.creators[0].name}
              </p>
            </div>
            {/* <div className="flex-shrink-0 ml-4 bg-opacity-70 text-white px-3 py-1 rounded-full text-sm">
              {track.playCount?.toLocaleString()} plays
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}
